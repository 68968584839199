#aemp-infowindow-container {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.aemp-infowindow {
  position: absolute;
  right: 10%;
  top: 215px;
  max-height: calc(100% - 215px - 35px);
  width: 350px;
  margin: 10px 10px 0 0;
  overflow-y: auto;
  padding: 0 15px 15px 15px;
  background: white;
  font-family: "Noto Sans", sans-serif;
  border: 1px solid $rent-strike-color;
}

@media (max-width: 900px) {
  .aemp-infowindow {
    // bottom: 40px;
  }
}

@media (max-width: 640px) {
  .aemp-infowindow {
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: 0;
    z-index: 1000;
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    height: 100%;
  }
}

.aemp-infowindow p {
  font-size: 1.4rem;
  overflow-wrap: break-word;
  overflow-x: hidden;
}

.aemp-infowindow p.infowindow-title {
  font-family: "IBM Plex Mono", monospace;
  font-size: 1.8rem;
  color: $rent-strike-color;

  @media (max-width: 640px) {
    max-width: 80%;
  }
}

.aemp-infowindow-close {
  display: inline-block;
  position: absolute;
  right: 10px;
  top: -20px;
  font-size: 5.4rem;
  font-style: normal;
  font-weight: 500;
  color: $rent-strike-color;
}

.aemp-infowindow-close:hover {
  text-decoration: none;
  color: $link-visited;
}

.aemp-infowindow .policy-strength-color {
  display: inline-block;
  padding: 2px 6px;
  font-weight: bold;
}

.aemp-infowindow .policy-strength-color--1 {
  background-color: $policy-color-bg-1;
  border: 1px solid $policy-color-str-2;
  color: $policy-color-str-3; //white font
}

.aemp-infowindow .policy-strength-color--2 {
  background-color: $policy-color-bg-2;
}

.aemp-infowindow .policy-strength-color--3 {
  color: #000;
  background-color: $policy-color-bg-3;
}

.infowindow-link > a {
  color: $font-color-black;
}

.infowindow-link:hover > a {
  color: $rent-strike-color;
  text-decoration: underline;
}

.infowindow-link:visited > a {
  color: $link-visited;
}

.pull-quote-container{
  background-color: whitesmoke;
  margin-top: 2em;
  padding-bottom:0.01em;
  padding-top:1em;
}

.pull-quote-container .pull-quote {
  font-style: italic;
  font-family: 'IBM Plex Mono', monospace;
  font-weight: 400;
  color: $font-color-black;
  font-size: 1em;
  line-height: 1.5em;
  margin-left: 15%;
  padding-right: 10%;
  &:before, &:after {
    color: #ff2600;
    transform: translateY(0.4em);
    display: block;
    font-family: Arial, Helvetica, sans-serif;
  }
  &:before {
    content: "“";
    font-size: 6em;
    margin-left: -20%;
    margin-top: -0.1em;
  }
  &:after {
    content: "”";
    margin-bottom: 0.5em;
    margin-left: 95%;
    margin-top: -0.30em;
    font-size: 6em;
  }
}

.eviction-story-title {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 700 !important;
  font-size: 1.8rem;
  color: $rent-strike-color;
  margin-top: 8%;
  margin-right: 8%;
}

.links{
  margin-top: 1em;
}

.links a{
  color: $rent-strike-color;
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
  border-bottom: 1px solid;
  line-height: 3em;
  transition: 0.5s;
}

.links a:hover{
  color: #ff2600;
  border-bottom: 5px solid;
  transition: 0.5s;
}

.links a:visited{
  color: $link-visited;
  transition: 1.5s;
}


.eviction-status{
  background-color: whitesmoke;
  font-family: "IBM Plex Mono", monospace;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
  line-height: 1.7em;
}

p{
  text-align: justify;
  letter-spacing: 0.25px;
  line-height: 1.4em;
}

.last-update {
  margin-top: 2.2em !important;
}

.last-update p{
  font-size: 0.9em !important;
  color: $link-visited;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-family: 'IBM Plex Mono', monospace;
}
