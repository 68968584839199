@font-face {
  font-family: "Harbour W00 Medium";
  src:
    url("/assets/fonts/Harbour_W00_Medium/Harbour_W00_Medium.woff")
    format("woff"),
    url("/assets/fonts/Harbour_W00_Medium/Harbour_W00_Medium.woff2")
    format("woff2");
}

@font-face {
  font-family: "ChicagoFLF";
  src:
    url("/assets/fonts/ChicagoFLF/ChicagoFLF.woff")
    format("woff"),
    url("/assets/fonts/ChicagoFLF/ChicagoFLF.woff2")
    format("woff2");
}
