.audioPlayer {
  margin-top: 1em;

  .controls {
    margin-top: 1em;
    display: flex;
  }


  // .share-box {
  //   // font-size: 1em;
  //   padding: 0em;
  //   text-align: center;
  // }

  .rangeslider-horizontal {
    border-radius: 0.2em;
    background: white;
    box-shadow: none;
    border: 1px solid black;
  }

  .rangeslider__fill {
    background: black;
    border-radius: 0.2em;
  }
  .rangeslider__handle {
    width: 2em;
    height: 2em;
    border-radius: 0.2em;
    border: none;
    box-shadow: none;
    background: black;
    &:after {
      display: none;
    }
  }
}

.visualizer {
  border: 1px solid black;
}

.audioControls {
  display: grid;
  flex-grow: 1;
  grid-template-columns: 1fr 1fr 1fr;
  span {
    width: 100%;
    height: auto;
    border: 1px solid black;
    text-align: center;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:first-of-type {
      border-radius: 5px 0 0 5px;
      border-right: none;
    }
    &:last-of-type {
      border-radius: 0 5px 5px 0;
      border-left: none;
    }
    img {
      margin: 0 auto;
      display: block;
      height: 2em;
      width: auto;
      margin-top: 15%;
    }
  }
}

.share.button {
  // display: inline-block;
  // height: 100%;
  // padding: 1em;
  // border: 1px solid black;
  // margin-left: 1em;
  // border-radius: 5px;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // width: 100%;
  height: auto;
  margin-left: 1em;
  border-radius: 5px;
  border: 1px solid black;
  text-align: center;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin: 0 auto;
    display: block;
    height: 2em;
    width: auto;
    margin-top: 15%;
  }
}

.slider-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.slider {
  width: 75%;
}

.slider-text {
  width: 25%;
}

.slider-text p {
  font-family: ChicagoFLF;
}

.button-text p{
  font-family: ChicagoFLF;
  margin-bottom: 0;
  margin-top: 0.5em;
}
