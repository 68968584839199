@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  // 80% {
  //   transform: scaleY(1.1);
  // }
  100% {
    transform: scaleY(1);
  }
}

#navigation {
  width: 100%;
  height: 266px;
  background-color: var(--black-text);
  background-color: white;
  min-width: 527px;
  a {
    color: $rent-strike-color;
    font-style: normal;
    text-transform: uppercase;
    transition: 0.5s;
    &:hover {
      color: $font-color-black;
      text-decoration: none;
      transition: 0.5s;
    }
  }
}

#HamburgerWrapper {
  display: none;
  text-align: right;
}

#HamburgerMenu {
  display: none;
}

#hamburgerImage {
  width: 5vw;
  height: 5vw;
  // margin-right: 100%;
  // margin-top: 35%;
  display: inline-block;
}

#NavMenu {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 20px;
  margin: 2px;
  outline: 2px solid red;
  display: flex;
  justify-content: space-around;
  align-self: center;
  flex-wrap: nowrap;
  font-size: 14px;
  font-family: 'IBM Plex Mono', monospace;
  background-color: red;
  z-index: 1;
}

#NavMenu .section1 {
  display: flex;
  justify-content: space-around;
  flex-grow: 1.5;
  text-align: center;
  border-right: 2px solid white;
}

#NavMenu .section2 {
  display: flex;
  justify-content: space-around;
  flex-grow: 1.5;
  text-align: center;
  border-right: 2px solid white;
}

#NavMenu .section3 {
  display: flex;
  justify-content: space-around;
  flex-grow: 0.5;
  text-align: center;
}

#NavMenu a {
  padding: 2px;
  transition: 0.25s;
  color: white;
  cursor: pointer !important;
}

#NavMenu a:hover {
  cursor: pointer !important;
  text-decoration: none;
  transition: 0.25s;
  color: white;
  background-color: $rent-strike-color;
}

#navHeader {
  position: relative;
  // top: 24px;
  width: 100%;
  border: solid 2px #ff0000;
}

#mainHeader {
  width: 100vw;
  text-align: center;
  padding: 0.2em 0 0.2em;
  margin: 0px;
  font-family: 'Harbour W00 Medium', 'IBM Plex Mono', monospace;
  font-size: 3.75rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ff0000;
  background-color: white;
  border-bottom: 5px solid #ff0000;
}

#mainHeader a {
  color: #ff0000;
  text-decoration: none;
  transition: 0.5s;
}

#mainHeader a:hover {
  color: $rent-strike-color;
  text-decoration: none !important;
  transition: 0.5s;
}

#mapLinks {
  display: flex;
  width: 100vw;
  background-color: white;
  transition: 0.5s;
  > * {
    width: 50%;
    text-align: center;
    margin: 0px;
    padding: 10px 0 10px 0;
    // padding-left: 6%;
    // padding-right: 6%;
    border-bottom: 8px solid #ff0000;
    border-left: 6px solid #ff0000;
    border-right: 2px solid #ff0000;
    font-family: 'IBM Plex Mono', monospace;
    display: inline-block;
    font-size: 18px;
    // line-height: 1.2em;
    color: #ff0000;
    background-color: white;
    letter-spacing: 0.2px;
    transition: 0.5s;

    &.active {
      border-left: 2px solid #ff0000;
      border-bottom: 20px solid #ff0000;
      border-right: 4px solid #ff0000;
      transition: 0.5s;
    }

    &.active:hover {
      transition: 0.5s;
    }

    &:hover {
      border-bottom: 20px solid red;
      text-decoration: none !important;
      transition: 0.5s !important;
    }
  }
}

#mapLinks:hover {
  transition: 0.5s;
  text-decoration: none;
}

// styling for dropdown in navbar
/* Dropdown Button */
.dropbtn {
  color: white;
  text-transform: uppercase;
  border: none;
  font-family: 'IBM Plex Mono', monospace;
  font-size: 14px;
  background-color: red;
  z-index: 699;
}

/* The container  needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer !important;
}

.dropbtn:hover {
  background-color: #c92a1d;
  transition: 0.25s;
  cursor: pointer !important;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #c92a1d;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  perspective: 1000px;
  animation: growDown 400ms ease-in-out forwards;
  transform-origin: top center;
  cursor: pointer !important;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: #c92a1d !important;
  background-color: white;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  text-decoration: none;
  display: block;
  text-align: left;
  border-bottom: 1px solid #c92a1d;
  cursor: pointer !important;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  color: white !important;
  border-bottom: 1px solid #ff0000;
  border-bottom: 1px solid #ff0000;
  transition: 0.25s;
  cursor: pointer !important;
}

.dropdown:hover > .dropbtn {
  background-color: #c92a1d !important;
  color: white;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: white;
}

@media screen and (max-width: 1100px) {
  #mainHeader {
    font-size: 30px;
  }

  #mapLinks a {
    font-size: 1.8vw !important;
  }
}

@media screen and (max-width: 890px) {
  #navHeader {
    border: none;
  }

  #mainHeader {
    font-size: 3.15vw;
    padding-left: 1%;
    border: none;
    text-align: left;
    width: 90vw;
  }

  #mapLinks {
    height: 100%;
    display: flex;
    > * {
      border-bottom: 4px solid #ff0000;
      border-left: 2px solid #ff0000;
      border-right: 4px solid #ff0000;
      font-family: 'IBM Plex Mono', monospace;
      display: inline-block;
      color: #ff0000;
      background-color: white;
      letter-spacing: 0.2px;
      transition: 0.5s;
      padding-left: 2%;
      padding-right: 2%;

      &.active {
        border-left: 2px solid #ff0000;
        border-bottom: 10px solid #ff0000;
        border-right: 4px solid #ff0000;
        transition: 0.5s;
      }

      &.active:hover {
        transition: 0.5s;
      }

      &:hover {
        border-bottom: 10px solid red;
        text-decoration: none !important;
        transition: 0.5s !important;
      }
    }
  }

  #mapLinks a {
    font-size: 2.7vw;
    letter-spacing: 0.5px;
    font-family: 'IBM Plex Mono', monospace;
    line-height: 150% !important;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5%;
  }

  br {
    display: none;
  }

  #navHamburger {
    display: block;
    border: none !important;
  }

  #NavMenu {
    display: none;
  }

  #navHeader {
    display: block;
    top: 0;
    posititon: sticky;
  }

  .ham-nav-container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    border-top: 3px solid red;
    border-bottom: 4px solid red;
    border-left: 2px solid red;
    border-right: 4px solid red;
    background-color: white;
  }

  #HamburgerWrapper {
    display: inline-block;
    max-width: 10vw;
    margin-right: 1%;
    margin-top: 1%;
    border: none !important;
    z-index: 699;
    cursor: pointer !important;
  }

  #HamburgerMenu {
    text-align: right;
    font-family: 'IBM Plex Mono', monospace;
    color: #c92a1d;
    background-color: white;
    border: 4px solid #ff0000;
    width: 100vw;
    position: fixed;
    top: 0;
    right: 0;
    perspective: 1000px;
    animation: growDown 400ms ease-in-out forwards;
    transform-origin: top center;
    cursor: pointer !important;
  }

  #HamburgerMenu a {
    font-size: 2.5vw;
    border-bottom: 4px solid #ff0000;
    border-left: 0px solid #ff0000;
    border-right: 0px solid #ff0000;
    padding-bottom: 3%;
    padding-top: 3%;
    padding-left: 2%;
    padding-right: 2%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-decoration: none;
    transition: 0.25s;
    text-align: right;
    cursor: pointer !important;
  }

  #HamburgerMenu a:hover {
    background-color: #f1f1f1;
    border-bottom: 4px solid #ff0000;
    transition: 0.25s;
    text-decoration: none;
    border-right: 0px solid #ff0000;
    cursor: pointer !important;
  }

  #HamburgerMenu .aemplink:hover {
    border-left: 2.5em solid #ff0000;
  }

  #HamburgerMenu .mapslink:hover {
    border-left: 5em solid #ff0000;
  }

  #HamburgerMenu .aboutlink:hover {
    border-left: 7.5em solid #ff0000;
  }

  #HamburgerMenu .resourceslink:hover {
    border-left: 9em solid #ff0000;
  }

  #HamburgerMenu .storieslink:hover {
    border-left: 11.5em solid #ff0000;
  }

  .dropbtn {
    color: #c92a1d;
    text-transform: uppercase;
    font-size: 2.5vw;
    font-family: 'IBM Plex Mono', monospace;
    text-align: right;
    border-left: 4px solid red;
    border-right: 4px solid red;
    border-bottom: 4px solid red;
    width: 100vw;
    position: relative;
    top: 0;
    right: 0;
    padding-bottom: 3%;
    padding-top: 3%;
    padding-left: 2%;
    padding-right: 2%;
    background-color: white;
    transition: 0.25s;
  }

  .dropbtn:hover {
    border-left: 13em solid #ff0000 !important;
    background-color: #f1f1f1 !important;
    position: relative;
    top: 0;
    right: 0;
    cursor: pointer !important;
  }

  .dropdown {
    position: relative;
    top: 0;
    left: -4px;
    display: block;
    text-align: right;
    width: 100vw !important;
    cursor: pointer !important;
  }

  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    background-color: #f1f1f1 !important;
    text-align: right;
    border-right: 8px solid red;
    border-bottom: 4px solid red !important;
    perspective: 1000px;
    animation: growDown 500ms ease-in-out forwards;
    transform-origin: top center;
  }

  .dropdown-content a {
    width: 100vw !important;
    color: $rent-strike-color !important;
    border-left: 4px solid red !important;
    border-right: 4px solid red !important;
    border-bottom: 1px solid red !important;
    padding-bottom: 3% !important;
    padding-top: 3% !important;
    padding-left: 2% !important;
    padding-right: 2% !important;
  }

  .dropdown-content a:hover {
    background-color: red !important;
    color: white !important;
  }

  .dropdown:hover > .dropbtn {
    border-left: 13em solid #ff0000;
    background-color: #f1f1f1 !important;
    border-bottom: 4px solid #ff0000;
    color: black;
  }

  .sub-nav {
    position: relative;
    cursor: pointer;
  }

  .list {
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
  }

  .list a {
    display: block;
    font-family: 'IBM Plex Mono', monospace;
    color: #c92a1d;
    border-bottom: 1px solid grey;
    background-color: #f1f1f1;
  }

  @media screen and (max-width: 400px) {
    #mainHeader {
      font-size: 2.75vw;
    }

    #mapLinks a {
      font-size: 2.6vw !important;
      text-align: justify;
      text-align-last: center;
      text-justify: inter-character;
      letter-spacing: 0.75px;
      letter-spacing-last: 1px;
      padding-left: 1%;
      padding-right: 1%;
    }
  }
}
