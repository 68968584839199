/**
 * APP WIDE VARIABLES
 * To use them in file you must @import them first
 */

// font defaults
$fontSizeDefault: 1.2rem; // = 12px
$fontStack: 'Noto Sans', sans-serif;

// colors
$rent-strike-color: #c92a1d;
$policy-color-bg-1: #5e5e5e;
$policy-color-bg-2: #bdbdbd;
$policy-color-bg-3: #f2f2f2;
$policy-color-str-1: #5e5e5e;
$policy-color-str-2: #bdbdbd;
$policy-color-str-3: #fff;
$link-visited: #797979;
$font-color-black: #000;
$highlight-color-yellow: #feff54;

$layout-nav-desktop-height: 3.5rem;


$borderLeftWidth: 30px;
// $newBorderWidth: 60px;
// $newBorderWidth: calc(#{$borderLeftWidth} + 30px);

:export {
  rentStrikeColor: $rent-strike-color;
}
