#search-bar-div {
  position: absolute;
  left: 35.5%;
  top: 165px !important;
  width: 35%;
  z-index: 600;

  @media (max-width: 1000px) {
    top: calc(-25px + 20vw) !important;
    left: 10px;
    right: 10px;
    bottom: 0;
    width: unset;
  }

  @media (max-width: 860px) {
    top: calc(-25px + 30vw) !important;
    left: 10px;
    right: 10px;
    bottom: 0;
    width: unset;
  }
}

#search-bar {
  width: 100%;
  font-size: 1.2em;
  display: block;
  float: left;
  border: 1px solid $rent-strike-color;
  border-radius: 0px;
  padding: 8px 6px;
  background-clip: padding-box;
  font-family: 'IBM Plex Mono', monospace;
  color: $link-visited;
}

.search-bar-no-data {
  border: $rent-strike-color 4px solid !important;
}

#search-bar-autocomplete {
  width: 100%;
  height: 50%;
  background-color: white;
  margin-top: 37px;
  padding: 0;
  cursor: pointer;
  font-family: 'IBM Plex Mono', monospace;

  li {
    padding: 6px 8px;
    list-style-type: none;
    font-size: 1.2em;
  }

  li:hover {
    background-color: #c92a1d !important;
    color: white;
  }
}

#search-box-marker-content {
  border: 1px solid black;
  background-color: #fff;
  font-size: 1.5em;
  text-align: center;
  padding: 10px;
  transform: translate(0, 40px);
}
