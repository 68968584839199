/* ----- Layer controller ----- */
.leaflet-control-layers {
  border: 1px solid $rent-strike-color !important;
  border-radius: 0 !important;
  font-family: 'IBM Plex Mono', monospace !important;
  box-shadow: none !important;
  position: absolute !important;
  overflow: auto;
  transform: translateY(-35px); // to account for leaflet #map being 35px down
  top: 200px !important;
  margin: 0px !important;
  right: 10px;
  width: 25vw;
  // height: auto;
  //show on mobile

  @media (max-width: 640px) {
    display: block !important;
    width: calc(max(30vw, 180px)) !important;
  }
}

html
  body
  div#root
  div#map_eviction_stories.leaflet-container.leaflet-touch.leaflet-fade-anim.leaflet-grab.leaflet-touch-drag.leaflet-touch-zoom
  div.leaflet-control-container
  div.leaflet-top.leaflet-left
  div.leaflet-control-layers.leaflet-control-layers-expanded.leaflet-control {
  left: 10px !important;
  width: calc(min(230px, 45vw)) !important;
  @media (max-width: 640px) {
    display: block !important;
    width: calc(max(30vw, 180px)) !important;
    transform: translateY(-65px);
  }
}

.leaflet-control-layers-list {
  height: inherit !important;
}

.leaflet-top {
  margin-top: 0px;
}

@media screen and (max-width: 1000px) {
  .leaflet-control-layers {
    top: calc(60px + 20vw) !important;
  }
}

@media screen and (max-width: 860px) {
  .leaflet-control-layers {
    top: calc(60px + 30vw) !important;
  }
  label {
    font-size: 2.2vh !important;
    text-transform: uppercase;
  }
}

@media screen and (max-width: 400px) {
  .leaflet-control-layers {
    top: calc(60px + 30vw) !important;
  }
  label {
    font-size: 1.2vh !important;
    text-transform: uppercase;
  }
}

.leaflet-left {
  left: 2.5% !important;
  margin-left: unset !important;
}

input[type='checkbox'] {
  display: none;
}

label {
  cursor: pointer;
}

/* ----- text color ----- */
input[type='checkbox'] + span {
  color: black;
  line-height: 25px;
}

/* ----- unchecked ----- */
input[type='checkbox'] + span::before {
  border: 1px solid black;
  content: '\00a0';
  display: inline-block;
  font: 16px sans-serif;
  height: 14px;
  width: 14px;
  margin-top: 0.25em;
  padding: 0;
  vertical-align: top;
}

/* ----- checked ----- */
input[type='checkbox']:checked + span::before {
  border: 1px solid black;
  background: $policy-color-str-3;
  color: $rent-strike-color;
  content: '\2715';
  text-align: center;
  line-height: 14px;
}

input[type='checkbox']:checked + span::after {
  font-weight: bold;
}
