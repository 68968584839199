@import '~leaflet/dist/leaflet.css';

#map_eviction_stories,
#map {
  position: absolute;
  top: $layout-nav-desktop-height;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;

  .leaflet-pane svg {
    path {
      @include use-svg-pattern('few');
      @include use-svg-pattern('few', true);
      @include use-svg-pattern('some');
      @include use-svg-pattern('some', true);
      @include use-svg-pattern('many');
      @include use-svg-pattern('many', true);
    }
  }
}

#map-pattern-fills {
  // the svg used for the map's pattern fills should be hidden in the UI
  position: absolute;
  left: -1000000px;
}

@media (max-width: 640px) {
  // when the infowindow & popup are open, reduce map height
  // #root.aemp-popupopen #map {
  //   // height: 50%;
  // }

  // Hide map layers controls on mobile for now, as they get obscured
  #root.aemp-popupopen .leaflet-control-attribution,
  .leaflet-control-layers.leaflet-control-layers-expanded.leaflet-control {
    display: none;
  }
}

@keyframes pulsing {
  0% {
    width: 50px;
    height: 52px;
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    width: 50px;
    height: 50px;
    box-shadow: 0 0 0 15px rgba(255, 82, 82, 0);
  }

  100% {
    width: 50px;
    height: 51px;
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.pulse-animation {
  border-radius: 50%;
  animation: pulsing 2s infinite;
}
