header#aemp-titlebox {
  position: absolute;
  top: 200px !important;
  left: 10px;
  bottom: 20px;
  overflow: auto;
  width: 320px;
  padding: 12px;
  background: #fff;
  border: 1px solid $rent-strike-color;
  z-index: 500;

  &.collapsed {
    height: 48px;
  }

  @media (max-width: 860px) {
    top: calc(60px + 30vw) !important;
  }

  @media (max-width: 640px) {
    width: unset;
    right: 10px;
    &.collapsed {
      height: 40px;
      width: calc(max(30vw, 100px)) !important;
    }
  }
}

.aemp-titlebox-closed {
  width: 30vw !important;
  padding: 5px !important;
}

.details > h1 {
  margin: 0.8rem 0 2.4rem 0;
  font-family: 'Harbour W00 Medium', 'IBM Plex Mono', monospace;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.8rem;
  color: $rent-strike-color;
}

@media (max-width: 640px) {
  header > h1 {
    font-size: 1.7rem;
  }
}

.details {
  padding: 4px;
  max-width: 306px;
  margin: 0 auto;
}

.summary {
  font-size: 1.8rem;
  font-weight: bold;
  cursor: pointer;
  font-family: 'IBM Plex Mono', monospace;
  color: $font-color-black;
  outline: none;
  padding-left: 1.1em;
  margin: 4px 2px;
  position: relative;

  &::before {
    content: '▼';
    position: absolute;
    left: 0em;
    top: 0.54em;
    display: inline-block;
    color: $rent-strike-color;
    font-size: 1em;
    transform-origin: center;
    transform: translateY(-50%);
    // transition: transform 0.1s ease;
  }

  &.collapsed {
    &::before {
      transform: translateY(-44%) rotate(-90deg);
    }
  }

  @media (max-width: 640px) {
    font-size: 1.4rem;
  }
}

.summary::-webkit-details-marker,
.summary::marker {
  color: $rent-strike-color;
}

.details p {
  font-size: 1.2rem;
  margin-top: 0.6rem;
}

@media (max-width: 640px) {
  .details p {
    font-size: 1.2rem;
  }
}

details p:last-of-type {
  margin-bottom: 0;
}

.title-content-wrapper {
  h2 {
    font-size: 1.4rem;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }

  p > a {
    color: $font-color-black;
  }

  p > a:hover {
    color: $rent-strike-color;
    text-decoration: underline;
  }

  p > a:visited {
    color: $link-visited;
  }
}

.submit-container {
  display: flex;

  p:first-child {
    margin-right: 12px;
    flex-shrink: 0;
    font-family: 'IBM Plex Mono', monospace;
    color: $rent-strike-color;
  }
}
