div.legend {
  width: 100%;
  margin-bottom: 50px;
}

div.legendgrid {
  display: grid;
  margin-top: 16px;

  &.key,
  &.submissions {
    grid-template-columns: min-content auto;
    grid-template-rows: min;
    // align-items: center;
    justify-items: left;
  }

  :nth-child(even) {
    padding-left: 1.5em;
  }

  &.key {
    align-items: center;
  }

  p {
    margin: 0;
  }
}

div.submissions {
  margin-top: 32px;
  grid-row-gap: 16px;
  align-items: start;

  h3 {
    color: $rent-strike-color;
    margin: 0;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
  }
}

.aemp-logo {
  font-family: 'Harbour W00 Medium', 'IBM Plex Mono', monospace;
  color: $rent-strike-color;
  font-size: 1.6rem;
  font-style: normal;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
  }
}

.legendlabel {
  text-transform: uppercase;
  font-size: 1rem !important;
  text-align: left;
}

.legendbox {
  width: 95px;
  height: 52px;
  display: inline-block;
  border: 1px solid black;
  border-bottom: none;

  &.scale1 {
    background-color: $policy-color-bg-1;
  }

  &.scale2 {
    background-color: $policy-color-bg-2;
  }

  &.scale3 {
    background-color: $policy-color-bg-3;
  }

  &.missingdata {
    background-color: black;
    border-bottom-color: $policy-color-str-2;
  }

  &.expired {
    background: url('/assets/stripes-icon.png') center no-repeat;
    background-size: contain;
    height: 70px;
    border: none;
  }
}

.legendbox-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95px;

  .rent-strike-icon {
    background-image: url('/assets/mapIcons/rent-strike.svg');
    background-size: 55px 55px;
    height: 40px;
    width: 40px;
  }

  circle {
    fill: $rent-strike-color;
    stroke: 1px solid white;
  }

  .cluster-svg-text {
    fill: white;
    font-weight: bold;
  }

  :nth-child(even) {
    padding-left: 0 !important;
  }
}
