* {
  box-sizing: border-box;
}

html {
  /* sets default font size to 10px so that 1rem = 10px */
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font-family: $fontStack;
  font-size: $fontSizeDefault;
  overflow: hidden;
}

body::-webkit-scrollbar {
  background-color: turquoise;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

a {
  text-decoration: none;
  font-style: italic;

  &:hover {
    text-decoration: underline;
  }
}

#root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

// helper classes
.color-rent-strike {
  color: $rent-strike-color;
}

.leaflet-right {
  margin-right: 8px;

  @media (max-width: 640px) {
    margin-right: 0;
  }
}

.leaflet-control {
  @media (max-width: 640px) {
    margin-right: 0;
  }
}

.leaflet-top {
  margin-top: 8px;
}

//for os x
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background-color: $rent-strike-color;
}
